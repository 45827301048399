import React, { useState } from 'react';
import axios from 'axios';

const App = () => {
  const [response, setResponse] = useState(null);

  const handleFetch = async () => {
    try {
      const res = await axios.get('https://test.ninjaservicesllc.com/my-resource');
      setResponse(res.data.body);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="container">
      <h1>Serverless GET Request with CloudFront & Lambda@Edge</h1>
      <button onClick={handleFetch} className="btn btn-primary mt-3">Fetch Data</button>
      {response && <p>{response}</p>}
    </div>
  );
};

export default App;
